import React from 'react';
import Footer from '../components/Footer';
import Header from "../components/Header";
import SliderArea from "../components/hometwo/SliderArea";
import LiveArea from "../components/homeone/LiveArea";
import AfishaMain from "../components/Afisha/AfishaMain";
import Peredvijka from "../components/homeone/Peredvijka";



const Homeone = (props) => {
    let Films=[...props.Films]
if(Films.length!==0){
    return (
        <div>
            <Header/>
            <main>
                <SliderArea Films={Films}/>
                <AfishaMain Films={Films}/>
                <LiveArea/>
                <Peredvijka/>
            </main>
            <Footer/>
        </div>

    )



}

}

export default Homeone
