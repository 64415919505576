// import s from './Raspisanie.module.css'
import './Afisha.module.css';
import s from "./Afisha.module.css";
import React from "react";
const AfishaSceny = (props) => {

    const filter = props.filmSessions.filter((item)=>item.dataSession.toString().split(" ")[0]===props.activeDate.toString());
    return (
<>
    {
        filter.map((el) => {
           const {dataSession,priseSession}=el;
           const dataS=dataSession.toString().split(" ")[1];
            return (

                <li key={dataSession}>
                    <div className={s.containerSceny}>
                        <div className={s.qualitySceny}> {dataS}</div>
                        <div className={s.priseSceny}>{priseSession}р.</div>
                    </div>

                </li>
            )
        })
    }
</>

        )
}

export default AfishaSceny
