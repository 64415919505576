import React, {useState} from "react";
import {Button, Form, Radio} from "antd";
import {deleteFilms} from "../../features/counter/filmsSlice";
import {useDispatch} from "react-redux";
import cn from "classnames";
import s from "./login.module.css";


function FilmDelete(props) {
    const dispatch = useDispatch()
    const Films = props.Films;
    const [ChangeDeleteFilms, setChangeDeleteFilms] = useState();
    const [ButtonDisabled, setButtonDisabled] = useState(true);
    const [form] = Form.useForm();


    const onChangeDeleteFilmsRadio = (e) => {
        setChangeDeleteFilms(Films[e.target.value].id);
        setButtonDisabled(false)
    };
    const ButtonClickDeleteFilmPoId = () => {
        if (ChangeDeleteFilms !== null) {
            form.resetFields();
            dispatch(deleteFilms({DeleteFilm: ChangeDeleteFilms, Films: props.Films}))
            setButtonDisabled(true)
        }
    }




    const SampleOld=(item)=>{
        let value;
        const today = new Date();
        Films.forEach((obj) => {
            if ((obj.nameFilm === item)&&(obj.filmSessions.length !==0)) {
                if (obj.filmSessions.every(ses =>new Date(ses.dataSession) <today)) {
                   value=true
                    return value;
                } else {
                   value=false
                    return value;
                }
            }
        })
        return(value)
    }

    const SampleNow=(item)=>{
        let value;
        const today = new Date();
        Films.forEach((obj) => {
            if ((obj.nameFilm === item)&&(obj.filmSessions.length !==0)) {
                if (obj.filmSessions.some(ses =>new Date(ses.dataSession) >today)) {
                    value=true
                    return value;
                } else {
                    value=false
                    return value;
                }
            }
        })
        return(value)
    }



    const SampleNew=(item)=>{
       const value= props.Films.some(function(elem) {
            if ((elem.nameFilm ===item)&&(elem.filmSessions.length ===0)) {
                return true;
            } else {
                return false;
            }
        });
        return(value)
    }


    return (
        <section className="movie-area movie-bgMy"
                 style={{backgroundColor: "black", paddingTop: "50px"}}>
            <div className="container">
                <div style={{
                    border: '4px solid yellow',
                    display: 'flex',
                    flexDirection: "column",
                    textAlign: 'center',
                    backgroundColor: "grey"
                }}>

                    <div>
                        <h2 style={{margin: "20px 0 20px 0"}}>Удаление фильмов</h2>
                        <div className="row-cols-12 bg-white text-center"
                             style={{marginBottom: "30px", paddingBottom: "30px"}}>

                            <Form form={form}>
                                <Form.Item name="radio-group">

                                    <Radio.Group onChange={onChangeDeleteFilmsRadio}>
                                        {
                                            Films.map((el, index) => (

                                                    <Radio className={cn (SampleNow(el.nameFilm)&& s.now)} style={{marginRight: 35,marginTop:15, color: "black", width: 480}} key={index}
                                                           value={index} name="radiogroup"><img className={cn (SampleNew(el.nameFilm)&& s.border)} style={{margin: 10}}
                                                                                                src={el.urlPicture}
                                                                                                width={80} height={100}
                                                                                                alt={"img"}/> <span className={cn (SampleOld(el.nameFilm)&& s.red)}>{el.nameFilm} {el.id}</span>

                                                    </Radio>

                                                )
                                            )}
                                    </Radio.Group>

                                </Form.Item>
                            </Form>

                            <div className='bg-white text-center ' style={{paddingBottom: '20px'}}>
                                <Button onClick={ButtonClickDeleteFilmPoId} style={{
                                    border: '4px solid yellow',
                                    maxWidth: 480,
                                    width: 400,
                                    height: 100,
                                    fontSize: 20,
                                    backgroundColor: "gray"
                                }} disabled={ButtonDisabled} type="primary" htmlType="submit">Удалить
                                    выбранные </Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default FilmDelete