import React from "react";






const  SliderItem = (props) => {
    return (

<div className="slider-item">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6 order-0 order-lg-2">
                <div className="slider-img text-center text-lg-right animate__animated animate__fadeInRight"  data-delay="1s">
                    <img className="fotoslider" src={props.urlPicture} alt="" />
                </div>
            </div>
            <div className="col-lg-6">
                <div className="banner-content">
                    <h6 className="sub-title" data-delay=".2s">{props.nameFilm}</h6>
                    <h2 className="title animate__animated animate__fadeInUp"  data-delay=".4s">{props.description}</h2>
                    <div className="banner-meta animate__animated animate__fadeInUp"  data-delay=".6s">
                        <ul>
                            <li className="quality">
                                <span>+{props.yearLimit}</span>
                                <span>{props.formatAll}</span>
                            </li>
                            <li className="category">
                                <a href="/#">{props.genre}</a>
                            </li>
                            <li className="release-time">
                                <span><i className="far fa-calendar-alt" /> {props.ProductionYear}</span>
                                <span><i className="far fa-clock" /> {props.timeFilm} мин</span>
                            </li>
                        </ul>
                    </div>
                    <a href={props.trailer} className="banner-btn btn popup-video"  data-delay=".8s"><i className="fas fa-play" /> Смотреть</a>
                </div>
            </div>
        </div>
    </div>
</div>


    )
}

export default SliderItem