import React from "react";
import MovieNow from "../movienow/MovieNow";
import {Link} from "react-router-dom";
import s from '../Raspisanie/Raspisanie.module.css';
import cn from "classnames";


const Raspisanie = (props) => {
    const Films = props.Films;
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const uniqueDates = new Set();
    Films.forEach((film) => {
        film.filmSessions.forEach((session) => {
            const sessionDate = new Date(session.dataSession);
            if (sessionDate > yesterday) {
                // Преобразуем дату в формат "2023-12-25"
                const formattedDate = sessionDate.toISOString().split('T')[0];
                uniqueDates.add(formattedDate);
            }
        });
    });
    const sortedDates = Array.from(uniqueDates).sort();

    function findSessionsByDate(films, targetDate) {
        const result = [];
        let idf = 0;
        for (const film of films) {

            for (const session of film.filmSessions) {
                if (session.dataSession.startsWith(targetDate)) {
                    idf = idf + 1;
                    result.push({
                        pid: idf,
                        id: film.id,
                        dataSession: session.dataSession,
                        nameFilm: film.nameFilm,
                        priseSession: session.priseSession,
                        place: session.place,
                        format: session.format,
                        yearLimit: film.yearLimit,
                    });
                }
            }
        }

        // Функция для сравнения дат
        function compareDates(a, b) {
            const dateA = new Date(a.dataSession);
            const dateB = new Date(b.dataSession);
            return dateA - dateB;
        }

        result.sort(compareDates);
        return result;
    }

    function formatDateToDayMonth(dateString) {
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];
        const date = new Date(dateString);
        const day = date.getDate();
        const month = months[date.getMonth()];

        return `${day} ${month}`;
    }


    return (
        <div>
            <section className={cn(s.bg)} style={{backgroundImage: 'url("img/bg/contact_bg.jpg")'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="contact-form-wrap">
                                <div className="widget-title mb-50">
                                    <h5 className="title">Расписание сеансов</h5>
                                </div>


                                <div className={cn(s.blockFilmsN)}>

                                    {
                                        sortedDates.map((elem1) => {
                                            return (
                                                <div key={elem1.toString()} className={s.blockFilms}>
                                                    <h5>{formatDateToDayMonth(elem1)}</h5>
                                                    {
                                                        findSessionsByDate(Films, elem1).map((elem) => {
                                                            const {
                                                                dataSession,
                                                                nameFilm,
                                                                priseSession,
                                                                place,
                                                                format,
                                                                yearLimit,
                                                                id,
                                                                pid
                                                            } = elem;
                                                            return (
                                                                <span key={pid} className={s.blockFilm}>
                                                                    <span className={s.filmsTime}><i
                                                                        className="fa fa-film fa-lg"
                                                                        aria-hidden="true"></i>
                                                                        <span
                                                                            className={s.filmsTime}>{dataSession.split(' ')[1]}  </span>
                                                                        {/*<span className={s.nameFilm}>{nameFilm}</span>*/}
                                                                        <Link className={s.nameFilm}
                                                                              to={"/movie-details/" + id}>{nameFilm}</Link>
                                                                        <span className={s.film23D}>{format}</span>
                                                                         <span
                                                                             className={s.yearLimit}> {yearLimit}+ </span>
                                                                        <span className={s.filmPlace}>{place} </span>
                                                                        <span className={s.filmPrise}><span className={s.filmPriseText}>цена:</span> {priseSession}р. </span>

                                                                    </span>
                                                                    <p></p>

                                                                </span>


                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })


                                    }


                                </div>


                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4">

                            <div className={cn("contact-info-wrap  mt-85")}>
                                <MovieNow Films={props.Films}/>

                            </div>
                        </div>
                    </div>
                </div>


                <div className={cn(s.sdvoenny)}>

                    <div className={cn("")}>

                    </div>

                </div>


            </section>

        </div>

    )
}


export default Raspisanie
