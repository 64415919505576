import React, {useState} from 'react'
import {MailWebSite} from "../../features/counter/filmsSlice";
import {useDispatch} from "react-redux";
import cn from "classnames";
import s from "./contacts.module.css"

const Contacts = () => {
    const [formData, setFormData] = useState({name: "", email: "", message: "", tema: ""});
    const dispatch = useDispatch()
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [nameIsValid, setNameIsValid] = useState(true);
    const [themeIsValid, setThemeIsValid] = useState(true);
    const [messageIsValid, setMessageIsValid] = useState(true);
    const [postMessage, setPostMessage] = useState(false);
    let validationEmail = false;
    const validateEmail = () => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(formData.email)) {
            validationEmail = false;
            setEmailIsValid(false)
        } else {
            validationEmail = true;
            setEmailIsValid(true)
            setPostMessage(false)
        }
    };
    const validateName = () => {
        if (formData.name === undefined || formData.name === "") {
            setNameIsValid(false)
        } else {
            setNameIsValid(true)
            setPostMessage(false)
        }
    };
    const validateTheme = () => {
        if (formData.tema === undefined || formData.tema === "") {
            setThemeIsValid(false)
        } else {
            setThemeIsValid(true)
            setPostMessage(false)
        }
    };
    const validateMessage = () => {
        if (formData.message === undefined || formData.message === "") {
            setMessageIsValid(false)
        } else {
            setMessageIsValid(true)
            setPostMessage(false)
        }
    };
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };
    const handleSubmit = (values) => {
        if (formData.name !== "" && formData.message !== "" && formData.email !== "" && formData.tema !== "" && emailIsValid === true) {
            dispatch(MailWebSite({formData: formData}))
            setFormData({...formData, name: "", tema: "", message: "", email: ""})
            setPostMessage(true);
        } else {
        }
    };


    return (
        <section className="contact-area contact-bg" style={{backgroundImage: 'url("img/bg/contact_bg.jpg")'}}>
            <div className="container">
                <div className="row" style={{display:"flex"}}>
                    <div style={{marginBottom:50}} className="col-xl-6 col-lg-5 rdf0">
                        <div className="contact-form-wrap">
                            <div className="widget-title mb-50">
                                <h5 className="title">Контактная форма</h5>
                            </div>
                            <div className="contact-form">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input className={cn(!nameIsValid && s.error)} value={formData.name}
                                                   name="name" type="text" placeholder="Ваше имя *"
                                                   onChange={handleChange} onBlur={validateName}/>
                                        </div>
                                        <div className="col-md-6">
                                            <input className={cn(!emailIsValid && s.error)} value={formData.email}
                                                   name="email" type="email" placeholder="Ваш e-mail *"
                                                   onChange={handleChange} onBlur={validateEmail}/>
                                        </div>
                                    </div>
                                    <input className={cn(!themeIsValid && s.error)} value={formData.tema} name="tema"
                                           type="text" placeholder="Тема *" onChange={handleChange}
                                           onBlur={validateTheme}/>
                                    <textarea className={cn(!messageIsValid && s.error)} value={formData.message}
                                              name="message" placeholder="Ваше сообщение..."
                                              onChange={handleChange} onBlur={validateMessage}/>
                                </form>
                                <button className="btn" type="submit" onClick={() => handleSubmit()}>Отправить</button>
                                <div className={cn(postMessage ? s.message : s.notmessage)}>Сообщение отправлено!</div>
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom:50}} className="col-xl-5 col-lg-5 rdf1">
                        <div className="widget-title mb-50">
                            <h5 className="title">Информация</h5>
                        </div>
                        <div className="contact-info-wrap">
                            <div className="contact-info-list">
                                <ul>
                                    <li>
                                        <div className="icon"><i className="fas fa-map-marker-alt"/></div>
                                        <p><span>Адрес :</span> 225133, Брестская обл., г. Пружаны, ул. Ширмы, 5</p>
                                    </li>
                                    <li>
                                        <div className="icon"><i className="fas fa-phone-alt"/></div>
                                        <p><span>Директор :</span> 8 (01632) 4-45-21 <br/> понедельник-пятница
                                            8:00-13:00;
                                            14:00- 17:00;<br/> выходной - суббота, воскресенье</p>
                                    </li>
                                    <li>
                                        <div className="icon"><i className="fas fa-phone-alt"/></div>
                                        <p><span>Методист :</span> 8(01632) 3-59-08 <br/> понедельник-пятница
                                            8:00-12:00;
                                            13:00-17:00; <br/> выходной - суббота, воскресенье</p>
                                    </li>
                                    <li>
                                        <div className="icon"><i className="fas fa-phone-alt"/></div>
                                        <p><span>Касса :</span> 8(01632) 3-59-08 <br/> вторник - воскресенье
                                            11:00-20:00;<br/> выходной - понедельник</p>
                                    </li>
                                    <li>
                                        <div className="icon"><i className="fas fa-envelope"/></div>
                                        <p><span>Email :</span> kino_sputnik@pruzhany.by</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-5">
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Contacts