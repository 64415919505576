import { configureStore } from '@reduxjs/toolkit'
import FilmsSlice from './features/counter/filmsSlice'


export const store = configureStore({
    reducer: {
        FilmsReducer: FilmsSlice,
    },

})

// export default configureStore({
//     reducer: {
//         FilmsReducer: FilmsSlice,
//     },
// })


