import React from 'react'

const Map = () => {
  return (
   <>

       <div id="map"  style={{backgroundImage:'url( "img/bg/map.jpg")'}} >  </div>


       {/*    <div style={{display: "flex", position:'relative', overflow:'hidden'}} >*/}
       {/*        <a*/}
       {/*            href="https://yandex.by/maps/org/sputnik/1192223100/?utm_medium=mapframe&utm_source=maps"*/}
       {/*            style={{ position:'absolute', top:'0px'}}>Пружаны</a>*/}
       {/*        <a*/}
       {/*            href="https://yandex.by/maps/26929/pruzhany/category/cinema/184105868/?utm_medium=mapframe&utm_source=maps"*/}
       {/*            style={{position:'absolute',top:'14px'}}>Яндекс Карты — транспорт, навигация, поиск*/}
       {/*            мест</a>*/}
       {/*        <iframe src="https://yandex.by/map-widget/v1/?ll=24.459556%2C52.555892&mode=search&oid=1192223100&ol=biz&z=17.25" width='100%' height="500"*/}
       {/*                allowFullScreen="true" style={{position:'relative'}}></iframe>*/}

       {/*</div>*/}







   </>

     
     
     
 
 
  )
}

export default Map