import React,{ useEffect } from 'react'
import "magnific-popup";
import $ from "jquery";

const PriceMovie = (props) => {
  useEffect(()=>{
    $('.popup-video').magnificPopup({
      type: 'iframe'
    });
  },[])


    function formatDate(dateString) {
        const date = new Date(dateString);
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);

        if (date.toDateString() === today.toDateString()) {
            return "сегодня";
        } else if (date.toDateString() === tomorrow.toDateString()) {
            return "завтра";
        } else {
            const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];
            const dayOfWeek = daysOfWeek[date.getDay()];
            return dayOfWeek;
        }
    }




  return (
    <section className="episode-area episode-bg border-my" style={{backgroundImage:'url("../img/bg/episode_bg.jpg")'}}>
    <div className="container">
      <div className="row ">

          {
              props.ses.map((elem)=> {
                  const {dataSession,data1,data2,place} = elem;
                  return (

                          <div key={elem.dataSession} className=" prise-movie0 col-lg-12">
                              <div className="prise-movie1">{elem.data1}
                                  <div className="prise-movie2" >{formatDate(elem.dataSession)}</div>
                              </div>
                              <div className="prise-movie3">{elem.place}</div>
                              {
                                  elem.data2.split(", ").map((el)=> {

                                      return (
                                          <div key={el} className="prise-movie4">{el}</div>
                                      )})}






                          </div>




                  )
              })
          }




      </div>
    </div>
  </section>
  )
}

export default PriceMovie