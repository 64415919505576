import React from "react";

import { Button,  Form, Input } from 'antd';
import {InputPassword} from "../../features/counter/filmsSlice";
import {useDispatch} from "react-redux";



function LoginIsAut() {
    const dispatch = useDispatch()
    const onFinish = (value) => {
        dispatch(InputPassword({value: value}))
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };




    return (
        <>

            <section className="movie-area movie-bgMy"
                     style={{backgroundColor: "black", paddingTop: "120px"}}>
                <div className="container">
                    <div style={{
                        border: '4px solid yellow',
                        display: 'flex',
                        flexDirection: "column",
                        textAlign: 'left',
                        backgroundColor: "grey"
                    }}>
                        <h2 style={{margin: "20px 0 10px 0", textAlign: "center"}}>Авторизация</h2>
                        <div style={{
                            minwidth: 200,
                            minHeight: 200,
                            backgroundColor: "yellow",
                            color: "black",
                            fontSize: 20,
                            padding: 20,
                            margin: "30px"
                        }}>
                            <Form
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 16,
                                }}
                                style={{
                                    maxWidth: 600,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>


                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button type="primary" htmlType="submit">
                                       Авторизация
                                    </Button>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </section>


        </>
    )

}

export default LoginIsAut