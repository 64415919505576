// import s from './Raspisanie.module.css'
import './MovieNow.module.css';
import s from "./MovieNow.module.css";
import React from "react";
const MovieScenyNow = (props) => {

    const filter = props.filmSessions.filter((item)=>item.dataSession.toString().split(" ")[0]===props.activeDate.toString());

    return (
<>


    {
        filter.map((el) => {
           const {dataSession,priseSession}=el;
           const dataS=dataSession.toString().split(" ")[1];
            return (





                    <li key={dataSession}><span className={s.quality}> {dataS} <div className={s.prise2}>{priseSession}</div></span></li>










            )
        })
    }

</>

        )
}

export default MovieScenyNow
