import React from 'react'

import Footer from '../components/Footer'
import Header from '../components/Header'
import Raspisanie from "../components/Raspisanie/Raspisanie";

const RaspisanieText = (props) => {
  return (
    <>
    <Header/>
        <main>
           <Raspisanie Films={props.Films}/>
        </main>
    <Footer/>
    
    </>
  )
}

export default RaspisanieText