import React, {useContext, useEffect} from 'react';
import Slider from "react-slick";
import "magnific-popup";
import $ from "jquery";
import 'animate.css';
import SliderItem from "./SliderItem";

const SliderArea = (props) => {
    let filteredFilms = props.Films.filter(film => film.slide === true && film.description!=="");


  useEffect(()=>{
    $('.popup-video').magnificPopup({
      type: 'iframe'
    });
  
  
  },[])


  const settings={
    
    slidesToShow: 1,
    speed:500,
    arrows:false,
    autoplay:true,
    responsive: [
        {
            breakpoint: 1800,
            settings: {
              autoplay:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed:500,
                infinite: true,
            }
        },
        {
            breakpoint: 1500,
            settings: {
              autoplay:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed:500,
                infinite: true,
            }
        },
        {
            breakpoint: 1200,
            settings: {
              autoplay:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed:500,
                arrows: false,
                infinite: true,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                speed:500,
                arrows: false,
                autoplay:true,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                speed:500,
                slidesToScroll: 1,
                arrows: false,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed:500,
                autoplay:true,
                arrows: false,
            }
        },
    ]
  }

//функция которая обрезает слишком большой текст
    function Res(string){
      if(string===null||string===undefined){return ""}
        let pr="";
        let descrResLength=string.length;

        if (descrResLength>400){
            pr="";
            const firstThreeSentences = string.replace(/([.?!])\s/g, "$1|").split("|");
            for(let i=0;i<firstThreeSentences.length;i++){
                if((firstThreeSentences[i].length+pr.length)<350){pr+=firstThreeSentences[i]; }
            }
        }else {pr=string}


        return pr;
    }




  return (
      <section className="slider-area slider-bg" style={{ backgroundImage:'url("../../img/banner/banner_bg01.jpg")' }}>

        <Slider className="slider-active" {...settings}>
            {filteredFilms.map(item => (
                <SliderItem key={item.id}
                            nameFilm={item.nameFilm}
                            urlPicture={item.urlPicture}
                            trailer={item.trailer}
                            description={Res(item.description) }
                            yearLimit={item.yearLimit}
                            formatAll={item.formatAll}
                            ProductionYear={item.ProductionYear}
                            genre={item.genre}
                            timeFilm={item.timeFilm}
               />
            ))}





        </Slider>
      </section>
  )
}

export default SliderArea