import React,{ useEffect } from 'react';
import $ from "jquery";
import 'magnific-popup';
import Counterup from '../NumberIncrementer';
import 'animate.css';
import NumberIncrementer from "../NumberIncrementer";
import NumberCounter from "../NumberIncrementer";



const LiveArea = () => {
  useEffect(()=>{
    
    $('.popup-video').magnificPopup({
			type: 'iframe'
		});
	

  },[])

  return (
    <section className="live-area live-bg fix" style={{backgroundImage:'url("../../img/bg/live_bg.jpg")'}}>
    <div className="container">
      <div className="row align-items-center">
        <div className="col-xl-5 col-lg-6">
          <div className="section-title title-style-two mb-25">
            <span className="sub-title">Воспользуйся услугой личный кинозал</span>
            <h2 className="title">Приходи с друзьями и наслаждайтесь просмотром любимых фильмов</h2>
          </div>
        
          <div className="live-movie-content">
            <p>Каталог с фильмами постоянно обновляется</p>
            <div className="live-fact-wrap">
              <div className="resolution">
                <h2>МАЛЫЙ ЗАЛ</h2>
              </div>
            
              <div className="active-customer">
                <h4>  <NumberCounter/>   +</h4>
               
                <p>лучших фильмов на Ваш выбор</p>
              </div>
            
            </div>
            {/*<a href="https://www.youtube.com/watch?v=R2gbPxeNk2E" className="btn popup-video"><i className="fas fa-play" /> Watch Now</a>*/}
          </div>
       

          
        </div>

        <div className="col-xl-7 col-lg-6">
          <div className="live-movie-img animate__animated animate__fadeInRight" data-wow-delay=".5s" data-wow-duration="5s">
            <img src="/img/images/live_img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default LiveArea