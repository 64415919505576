import React, {useState} from "react";
import {Button, Form, Input, Radio} from "antd";
import ReactPlayer from "react-player";
import axios from "axios";
import {NewFilmSl} from "../../features/counter/filmsSlice";
import {useDispatch, useSelector} from "react-redux";
import {URLmy} from "../../config/config";


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

function arToStr(arr) {
        if(arr!=null){ return arr.map(obj => Object.values(obj).join(', ')).join(', ');}

}
const transformUrl = (url) => {
    const baseUrl = 'https://avatars.mds.yandex.net/get-kinopoisk-image/';
    const parts = url.split('/');
    const id = parts[parts.length - 3];
    const hash = parts[parts.length - 2];
    const newSize = '300x450';
    return `${baseUrl}${id}/${hash}/${newSize}`; };

function NewFilm(props) {
    const dispatch = useDispatch()
    const keyKinopoisk = useSelector((state) => state.FilmsReducer.keyKinopoisk)
    const newTrailers = [];
    let selectTrailer = '';
    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [filmsName, setFilmsName] = useState([]);
    const [trailer, setTrailer] = useState([]);
    const [ButtonDisabled, setButtonDisabled] = useState(true);


    const handleButtonClick = () => {
        const value = document.getElementById('input').value;
        FilmsALLName(value);
    };

    const FilmsALLName = (namefilm) => {
        const newNames = [];
        const options3 = {
            method: 'GET',
            url: 'https://api.kinopoisk.dev/v1.4/movie/search',
            params: {page: '1', limit: '5', query: `${namefilm}`},
            headers: {accept: 'application/json', 'X-API-KEY': `${keyKinopoisk}`}
        };
        axios.request(options3).then(function (response) {
            for (let i = 0; i < response.data.docs.length; i++) {
                newNames.push(response.data.docs[i]);
            }
            setFilmsName([...filmsName, ...newNames]);
        })
    }
    const onChange = (e) => {
        const id = filmsName[e.target.value].id;
        const options = {
            method: 'GET',
            url: `https://api.kinopoisk.dev/v1.4/movie/${id}`,
            headers: {accept: 'application/json', 'X-API-KEY': `${keyKinopoisk}`}
        };
        axios
            .request(options)
            .then(function (response) {
                const {
                    name,
                    year,
                    genres,
                    description,
                    countries,
                    movieLength,
                    ageRating,
                    poster,
                    id,
                    persons
                } = response.data;
                const fields = {
                    nameFilm: name,
                    productionYear: year,
                    description: description,
                    genre: arToStr(genres),
                    country: arToStr(countries),
                    timeFilm: movieLength,
                    yearLimit: ageRating,
                    id: id,
                    director: persons.filter(obj => obj.enProfession === "director").map(obj => obj.name).join(', '),
                    playRole: persons.filter(obj => obj.enProfession === "actor").map(obj => obj.name).slice(0, 5).join(', '), };
                if (poster && poster.previewUrl) { fields.urlPicture = transformUrl(poster.previewUrl); }

                form.setFieldsValue(fields);
                ApiGetNameFilm(name);
            })
            .catch(function (error) {
                console.error(error);
            });
    };

    const ApiGetNameFilm = (namefilm) => {
        const options = {
            method: 'GET',
            url: `${URLmy}/trailer?namefilm=${namefilm}`,
        };
        axios.request(options).then(function (response) {
            for (let i = 0; i < response.data.result.length; i++) {
                newTrailers.push(response.data.result[i]);
            }
            setTrailer(newTrailers);
        })
    }

    const onChangeTrailer = (e) => {
        selectTrailer = trailer[e.target.value].url;
        setButtonDisabled(false);
    };

    const onFinish1 = (values) => {

    };
    const onFinish2 = (values) => {
        console.log(values)
        values.trailer = selectTrailer;
        values.filmSessions = []
        values.slide = false
        if (values.likes === undefined) {
            values.likes = 1
        }
        if (values.formatAll === undefined) {
            values.formatAll = "2D"
        }
        if (values.amountViews === undefined) {
            values.amountViews = 1
        }
        if (values.yearLimit === null) {
            values.yearLimit = 6
        }

        values.nameFilm = document.getElementById('input').value;
        dispatch(NewFilmSl({Film: values, Films: props.Films}))
        setTrailer([])
        setFilmsName([])
        form.resetFields();
        form1.resetFields();
    };


    return (
        <>
            <section className="movie-area movie-bgMy"
                     style={{backgroundColor: "black", paddingTop: "50px", paddingBottom: "50px"}}>
                <div className="container col-lg-12">
                    <div style={{
                        border: '4px solid yellow',
                        display: 'flex',
                        flexDirection: "column",
                        backgroundColor: "grey"
                    }}>

                        <h2 style={{margin: "20px 0 20px 0", textAlign: 'center'}}>Добавление новых фильмов</h2>

                        <div className='col-8 mb-30 mt-20' style={{}}>
                            <div>
                                <Form form={form1}>
                                    <Form.Item name="group">
                                        <Input id="input" style={{maxWidth: 600}}
                                               placeholder="Введите название фильма"/>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div><Button onClick={handleButtonClick} type="primary">Найти</Button></div>
                        </div>
                        <div className='row col-12'>
                            <div className='col-lg-5 '>

                                <Form {...layout} form={form} name="nest-messages"
                                      initialValues={{
                                          ["formatAll"]: "2D"
                                      }}
                                      style={{maxWidth: 600}}
                                      validateMessages={validateMessages}
                                      onFinish={onFinish1}
                                >
                                    <Form.Item name='nameFilm' label="Название фильма">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='id' label="id  фильма">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='genre' label="Жанр">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='country' label="Страна">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='urlPicture' label="URL постера">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='timeFilm' label="Продолжительность фильма">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='director' label="Режиссер">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='playRole' label="Роли исполняют">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='description' label="Описание">
                                        <Input.TextArea/>
                                    </Form.Item>
                                    <Form.Item name='productionYear' label="Год выхода">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='yearLimit' label="Ограничения по возрасту">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='trailer' label="Трейлер">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='formatAll' label="формат фильма(2D/3D)">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='amountViews' label="Просмотры">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='likes' label="Лайки">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item name='slide' label="Слайд">
                                        <Input/>
                                    </Form.Item>
                                </Form>
                            </div>

                            <div className='col-lg-3 '>
                                <div style={{maxWidth: 500}}>
                                    <Radio.Group onChange={onChange}>
                                        {filmsName.map((el, index) => (
                                            <Radio key={index} value={index}>
                                                <img style={{ margin: 10 }}
                                                     src={el.poster && el.poster.previewUrl ? el.poster.previewUrl : 'default-image-url'} width={100} height={150} alt={`${el.name} poster`} />


                                                {el.name} {el.year}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                </div>
                            </div>

                            <div className='col-lg-4'>
                                <div style={{maxWidth: 400}}>
                                    <Radio.Group onChange={onChangeTrailer}> {trailer.map((el, index) => (
                                        <Radio key={index} value={index}>
                                            <div><ReactPlayer width={400} height={200} url={el.url}/></div>
                                            {el.title} {el.uploadedAt}
                                        </Radio>
                                    ))}
                                    </Radio.Group>
                                </div>

                            </div>
                        </div>


                        <div className='pb-10 pt-30'>
                            <Form {...layout} form={form} name="nest-messages2"
                                  onFinish={onFinish2}
                            >
                                <Form.Item name='button' wrapperCol={{...layout.wrapperCol, offset: 8,}}>
                                    <Button style={{
                                        border: '4px solid yellow',
                                        maxWidth: 480,
                                        width: 400,
                                        height: 100,
                                        fontSize: 20,
                                        backgroundColor: "gray"
                                    }} disabled={ButtonDisabled} type="primary" htmlType="submit">Занести в
                                        базу </Button>
                                </Form.Item>
                            </Form>
                        </div>

                    </div>
                </div>

            </section>


        </>


    )


}

export default NewFilm