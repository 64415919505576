import React,{ useEffect } from 'react'
import $ from "jquery";
import "magnific-popup";
import {Link} from "react-router-dom";
import cn from "classnames";
import s from "../Afisha/Afisha.module.css";



const MovieDetail = (props) => {
  useEffect(()=>{
		$('.popup-video').magnificPopup({
			type: 'iframe'
		});
	},[])


  return (

    <section className="movie-details-area" style={{backgroundImage:'url("../img/bg/movie_details_bg.jpg")'}}>

    <div className="container">
      <div className="row align-items-center position-relative">
        <div className="col-xl-3 col-lg-4">
          <div className="movie-details-img">
            <img src={props.movie.urlPicture} alt="" />
            <Link to={props.movie.trailer} className="popup-video"><img src="/img/images/play_icon.png" alt="" /></Link>
          </div>
        </div>
        <div className="col-xl-6 col-lg-8">
          <div className="movie-details-content">
            <h5>Новый фильм</h5>
            <h2>{props.movie.nameFilm}</h2>
            <div className="banner-meta">
              <ul>
                <li className="quality">
                  <span>{props.movie.formatAll}</span>
                  <span>{props.movie.yearLimit}+</span>
                </li>
                <li className="category">
                  {props.movie.genre}
                </li>
                <li className="release-time">
                  <span><i className="far fa-calendar-alt" /> {props.movie.ProductionYear}</span>
                  <span><i className="far fa-clock" /> {props.movie.timeFilm} мин.</span>
                </li>
              </ul>
            </div>
            <p className="description">{props.movie.description}</p>
            <div className="movie-details-n">
              <div className="movie-details-n-item">Страна:
              <span className="movie-details-n-item1"> {props.movie.country}</span>
              </div>
              <div className="movie-details-n-item">Режиссер:
                <span className="movie-details-n-item1"> {props.movie.director}</span>
              </div>
              <div className="movie-details-n-item">В ролях:
                <span className="movie-details-n-item1"> {props.movie.playRole}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="movie-details-btn">
          <div    className={cn(props.movie.filmSessions.length===0 && s.none)}>
            <a href="https://bycard.by/objects/prujany/2/4936" className="download-btn" >Купить билет <img src="/fonts/download.svg" alt="" /></a>
          </div>


        </div>
      </div>
    </div>
  </section>
  )
}

export default MovieDetail