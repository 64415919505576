import React, { useState} from 'react';
import s from './Afisha.module.css';
import cn from 'classnames';
import AfishaSceny from "./AfishaSceny";
import {Link} from "react-router-dom";


const AfishaMain = (props) => {

    const today = new Date();
    today.setHours(0, 0, 1, 0);//устанавливаем часы
    const uniqueDates = new Set();
    props.Films.forEach((film) => {
        film.filmSessions.forEach((item) => {
            const date = new Date(item.dataSession);
            if (today < date) {
                const formattedDate = `${date.getFullYear()}-${getMonthIn(date.getMonth())}-${getDayIn(date.getDate())}`;
                uniqueDates.add(formattedDate);
            }

        });
    });
    const sortedUniqueDates = Array.from(uniqueDates);
    sortedUniqueDates.sort((a, b) => new Date(a) - new Date(b));
    let massDate = [];

    sortedUniqueDates.forEach((item) => {
        if (item === TomorrowToday('today')) {
            massDate.push("СЕГОДНЯ")
        } else {
            massDate.push(item.split('-')[2] + ' ' + getMonthName(item.split('-')[1]))
        }
    })

    const massDateObj = [];
    for (let i = 0; i < massDate.length; i++) {
        let obj = {
            id: i.toString(),
            NameYesMonth: massDate[i].toString(),
            NameNoMonth: sortedUniqueDates[i].toString()
        };
        massDateObj.push(obj);
    }
    const filteredFilmsAll = props.Films.filter((film) => {
        return film.filmSessions.some((session) => Date.parse(session.dataSession) > today);
    });

    function getMonthName(x) {
        let d = parseInt(x.toString()) - 1;
        const monthNames = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        return monthNames[d];
    }
    function getMonthIn(x) {
        let d = parseInt(x.toString());
        d = d + 1;
        let st = d.toString();
        let col = st.length;
        if (col === 1) {
            st = '0' + st;
        }
        return st;
    }
    function getDayIn(x) {
        let d = parseInt(x.toString());
       // d = d + 1;
        let st = d.toString();
        let col = st.length;
        if (col === 1) {
            st = '0' + st;
        }
        return st;
    }
    function TomorrowToday(tip) {
        const nDate = new Date();
        if (tip === "tomorrow") {
            nDate.setDate(nDate.getDate() + 1);
        }
        if (tip === "today") {
            nDate.setDate(nDate.getDate());
        }
        const dd = String(nDate.getDate()).padStart(2, '0');
        const mm = String(nDate.getMonth() + 1).padStart(2, '0'); // Январь - 0!
        const yyyy = nDate.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    }

    const [items, setItems] = useState(filteredFilmsAll);//первоначальный фильтр на все фильмы больше текущей даты
    const [activeButton, setActiveButtons] = useState('all');
    const [activeButtonDate, setActiveButtonsDates] = useState('all');



    const filterItem = (datItem, id) => {
        // фильтрируем по дате
        let filteredFilms = props.Films.filter((film) => {
          return  film.filmSessions.some((session) => session.dataSession.startsWith(datItem.toString()));
        });
        // оставляем только сеансы по выбранной дате
        let mySort=[...filteredFilms]
        const filteredFilmsN = mySort.map(film => {
            const filteredSessions = film.filmSessions.filter(session => {
                const sessionDate = session.dataSession.split(" ")[0]; // Извлекаем дату из строки
                return sessionDate === datItem;
            });
            return { ...film, filmSessions: filteredSessions };
        });
        // сортируем с учетом времени
        let mySortN=[...filteredFilmsN]
        mySortN.sort((a, b) => new Date(a.filmSessions[0].dataSession) - new Date(b.filmSessions[0].dataSession));
        setItems(mySortN);
        setActiveButtons(id);
        setActiveButtonsDates(datItem);
    }



    const filterItemAll = (id) => {
        const filteredFilms = props.Films.filter((film) => {
            return film.filmSessions.some((session) => Date.parse(session.dataSession) > today);

        });
        setItems(filteredFilms);
        setActiveButtons(id);

    }


    return (


        <section className="top-rated-movie tr-movie-bg"
                 style={{backgroundImage: 'url("../../img/bg/tr_movies_bg.jpg")'}}>

            {props.Films.length !== null && <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="section-title text-center mb-50">
                            <h2 className="title">Сейчас в кино</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="tr-movie-menu-active text-center">
                            <div>
                                <button className={cn('all'.toString() === activeButton.toString() && 'active')}
                                        id={'all'} onClick={() => filterItemAll('all')}>все фильмы
                                </button>
                                {massDateObj.map(el => (
                                    <button className={cn(el.id.toString() === activeButton.toString() && 'active')}
                                            id={el.NameNoMonth} key={el.id}
                                            onClick={() => filterItem(el.NameNoMonth, el.id)}>{el.NameYesMonth}</button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row tr-movie-active">
                    {

                        items.map((elem) => {
                            const {id, urlPicture, yearLimit, nameFilm, filmSessions} = elem;
                            return (

                                <div className="col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two" key={id}>
                                    <div className="movie-item mb-60">
                                        <div className="movie-poster">
                                            <div className={s.topleft}>+{yearLimit}</div>
                                            <Link to={"/movie-details/" + id}><img src={urlPicture} alt=""/></Link>
                                        </div>
                                        <div className={s.moviecontent}>
                                            <div className={s.top}>
                                                <h5 className={s.title}><Link
                                                    to={"/movie-details/" + id}>{nameFilm}</Link></h5>
                                            </div>
                                            <div className={s.bottom}>
                                                <ul className={cn(activeButton.toString() === 'all' && s.none)}>
                                                    <AfishaSceny filmSessions={elem.filmSessions}
                                                                 activeDate={activeButtonDate}/>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })


                    }
                </div>

            </div>}


        </section>
    )
}

export default AfishaMain