import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {URLmail, URLmy} from "../../config/config";
import {useSelector} from "react-redux";

let PkeyG="";
let PGoogleSheetName="";
let PkeyKinopoisk="";
const initialState = {
    value: 0,
    isLoader: "false",
    IsAut:"false",
    Films: [],
    GoogleSheetsPush: [],
    FilmsAnons:[],
    keyG:"",
    GoogleSheetName:"",
    keyKinopoisk:""
}


export const InputPassword = createAsyncThunk('films/InputPassword',
    async ({value}, {rejectWithValue, dispatch}) => {
        try {
            const res = await axios.post(`${URLmy}/apiPasswordFilms/`, value)
            let {status,keyG,GoogleSheetName,keyKinopoisk} = res.data;
            if (status === "ok") {
                dispatch(setKeyG(keyG))
                dispatch(setGoogleSheetName(GoogleSheetName))
                PkeyG=keyG
                PGoogleSheetName=GoogleSheetName
                PkeyKinopoisk=keyKinopoisk
                dispatch(getFilmsAnons());
                dispatch(getGoogleSheetsPush());
                dispatch(setIsAut("true"))
                dispatch(setKeyKinopoisk(PkeyKinopoisk))
            }
        } catch (err) {
            console.log("Ошибка InputPassword")
            return rejectWithValue(err.response.data);
        }
    })

export const getFilms = createAsyncThunk('films/getFilms',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            const res = await axios.get(`${URLmy}/`)
            dispatch(setFilms(res.data))
        } catch (err) {
            console.log("films/getFilms")
            return rejectWithValue(err.response.data);
        }
    })

export const NewFilmSl = createAsyncThunk('films/NewFilm',
    async ({Film, Films}, {rejectWithValue, dispatch}) => {
        try {
            let newFilms = [...Films]
            newFilms.push(Film)
            const res = await axios.post(`${URLmy}/apiRecordFilms/`, newFilms)
            let {status} = res.data;
            if (status === "ok") {
                dispatch(getFilms());
            }
        } catch (err) {
            console.log("Ошибка NewFilm")
            return rejectWithValue(err.response.data);
        }
    })


function removeById(arr, id) {
    for (let i = 0; i < arr.length; i++) {
        //  console.log(arr[i].id)
        if (arr[i].id === id) {
            arr.splice(i, 1);
            break;
        }
    }
}

export const deleteFilms = createAsyncThunk('films/deleteFilms',
    async ({DeleteFilm, Films}, {rejectWithValue, dispatch}) => {
        try {
            let newFilms = [...Films]
            removeById(newFilms, Number(DeleteFilm));
            const res = await axios.post(`${URLmy}/apiRecordFilms/`, newFilms)
            let {status} = res.data;
            if (status === "ok") {
                dispatch(getFilms());
            }
        } catch (err) {
            console.log("Ошибка deleteFilm")
            return rejectWithValue(err.response.data);
        }
    })


export const getGoogleSheetsPush = createAsyncThunk('films/GoogleSheetsPush',
    async (_, {rejectWithValue, dispatch}) => {

        try {

            const res = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${PGoogleSheetName}/values/Dev!A1?key=${PkeyG}`)
            dispatch(setGoogleSheetsPush(JSON.parse(res.data.values)))
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })


export const GoogleSheetsPush = createAsyncThunk('films/GoogleSheetsPush',
    async ({GoogleSheetsPush, Films}, {rejectWithValue, dispatch}) => {
        try {
            console.log("GoogleSheetsPushSlice");
            let newFilms = [...JSON.parse(JSON.stringify([...Films]))];
            let GogPush = [...JSON.parse(GoogleSheetsPush)]
            for (let i = 0; i < GogPush.length; i++) {
                let pushka = {
                    dataSession: GogPush[i].dataSession,
                    priseSession: GogPush[i].priseSession,
                    place: GogPush[i].place,
                    format: GogPush[i].format
                };
                newFilms.forEach((obj) => {
                    if (obj.nameFilm === GogPush[i].nameFilm) {
                        if (obj.filmSessions.some(ses => ses.dataSession === GogPush[i].dataSession)) {
                        } else {
                            obj.filmSessions.push(pushka);
                        }
                    }
                })
            }
            const res = await axios.post(`${URLmy}/apiRecordFilms/`, newFilms)
            let {status} = res.data;
            if (status === "ok") {
                dispatch(getFilms());
            }
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })



export const DeleteAllSeans = createAsyncThunk('films/GoogleSheetsDeleteAllSeans',
    async ({Films}, {rejectWithValue, dispatch}) => {
        try {
            console.log("GoogleSheetsPushSlice");
            let newFilms = [...JSON.parse(JSON.stringify([...Films]))];

            for (let i = 0; i < newFilms.length; i++) {
                newFilms[i].filmSessions = [];
            }
            const res = await axios.post(`${URLmy}/apiRecordFilms/`, newFilms)
            let {status} = res.data;
            if (status === "ok") {
                dispatch(getFilms());
            }
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })



export const getFilmsAnons = createAsyncThunk('films/FilmsAnons',
    async (_, {rejectWithValue, dispatch}) => {
        try {
            let datAnons = [];
            const url =`https://sheets.googleapis.com/v4/spreadsheets/${PGoogleSheetName}/values/%D0%A1%D0%9F%D0%98%D0%A1%D0%9E%D0%9A%20%D0%A0%D0%95%D0%9B%D0%98%D0%97%D0%9E%D0%92!A1:F100?key=${PkeyG}`
            const res = await axios.get(url)
            const keys = ['dateRelies', 'name', 'ageLimit', 'duration', 'country', 'genre'];
            const result = res.data.values.map((item) => {
                const obj = {};
                keys.forEach((key, index) => {
                    obj[key] = item[index];
                });
                return obj;
            });
            for (let i = 1; i < result.length; i++) {
                datAnons.push(result[i])
            }
            dispatch(setFilmsAnons(datAnons))

        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    })

export const MailWebSite = createAsyncThunk('films/MailWebSite',
    async ({formData}, {rejectWithValue, dispatch}) => {
         try {
             const {name,email,tema,message}=formData;
        var data = {
            service_id: 'service_kvo3cnm',
            template_id: 'template_qnmov8r',
            user_id: 'eHl3DYaY4p7wwRfYv',
            template_params: {
                'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...',
               name:name,
                email:email,
                tema:tema,
               message:message
            }
        };
            const res = await axios.post(URLmail, data)
                 let {status} = res.data;
                 if (status === "ok") {
             }
         } catch (err) {
             console.log("Ошибка MailWebSite")
             return rejectWithValue(err.response.data);
          }
    })


export const FilmsRedactor = createAsyncThunk('films/FilmsRedactor',
    async ({FilmsF}, {rejectWithValue, dispatch}) => {
        try {
            let newFilms = JSON.parse(FilmsF)

            const res = await axios.post(`${URLmy}/apiRecordFilms/`, newFilms)
            let {status} = res.data;
            if (status === "ok") {
                dispatch(getFilms());
            }
        } catch (err) {
            return rejectWithValue(err.response.data);
        }

    })


export const FilmsSlice = createSlice({
    name: 'films',
    initialState,
    reducers: {
        setFilms: (state, action) => {
            state.Films = action.payload
        },
        setFilmsAnons: (state, action) => {
            state.FilmsAnons = action.payload
        },
        setIsAut: (state, action) => {
            state.IsAut = action.payload
        },
        setKeyKinopoisk: (state, action) => {
            state.keyKinopoisk = action.payload
        },
        setKeyG: (state, action) => {
            state.keyG = action.payload
        },
        setGoogleSheetName: (state, action) => {
            state.GoogleSheetName = action.payload
        },
        setGoogleSheetsPush: (state, action) => {
            state.GoogleSheetsPush = action.payload
        },
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },

        setIsLoader: (state, action) => {
            state.isLoader = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {setIsLoader, setFilms,setIsAut, setGoogleSheetsPush,setFilmsAnons,setKeyG,setGoogleSheetName,setKeyKinopoisk} = FilmsSlice.actions

export default FilmsSlice.reducer