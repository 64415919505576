import React, {useContext, useState} from 'react';
import s from './MovieNow.module.css';
import cn from 'classnames';
import MovieScenyNow from "./MovieScenyNow";
import {Link} from "react-router-dom";





const MovieNow = (props) => {
    const Films =props.Films;
    function getMonthName(x) {
        let d=parseInt(x.toString())-1;
        const monthNames = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        return monthNames[d];
    }
    function getMonthIn(x) {
        let d=parseInt(x.toString());
        d=d+1;
        let st=d.toString();
        let col=st.length;
        if (col===1){ st='0'+st; }
        return st;
    }
    function TomorrowToday(tip) {
        const nDate = new Date();
        if (tip==="tomorrow"){
            nDate.setDate(nDate.getDate() + 1);
        }
        if (tip==="today"){
            nDate.setDate(nDate.getDate());
        }
        const dd = String(nDate.getDate()).padStart(2, '0');
        const mm = String(nDate.getMonth() + 1).padStart(2, '0'); // Январь - 0!
        const yyyy = nDate.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
    }
    const uniqueDates = new Set();
    const today = new Date();
    today.setHours(0, 0, 1, 0);//устанавливаем часы
    Films.forEach((film) => {
        film.filmSessions.forEach((item) => {
            const date = new Date(item.dataSession);
            if(today<date){
                const formattedDate = `${date.getFullYear()}-${getMonthIn(date.getMonth())}-${date.getDate()}`;
                uniqueDates.add(formattedDate);
            }
        });
    });
    const sortedUniqueDates = Array.from(uniqueDates);
    sortedUniqueDates.sort((a, b) => new Date(a) - new Date(b));
    let massDate=[];
    sortedUniqueDates.forEach((item)=>{
        if (item===TomorrowToday('today')){
            massDate.push("СЕГОДНЯ")
        }else{
            massDate.push( item.split('-')[2]+' '+getMonthName(item.split('-')[1]))
        }

    })
    const massDateObj=[];
    for(let i=0;i<massDate.length;i++){
        let obj = {
            id: i.toString(),
            NameYesMonth: massDate[i].toString(),
            NameNoMonth: sortedUniqueDates[i].toString()
        };
        massDateObj.push(obj);
    }
    const filteredFilmsAll = Films.filter((film) => {
        return film.filmSessions.some((session) => Date.parse(session.dataSession)>today);
    });
    const [items, setItems] = useState(filteredFilmsAll);//первоначальный фильтр на все фильмы больше текущей даты
    const [activeButton, setActiveButtons] = useState('all');
    const [activeButtonDate, setActiveButtonsDates] = useState('all');


  const filterItem = (datItem,id)=> {
        const filteredFilms = Films.filter((film) => {
            return film.filmSessions.some((session) => session.dataSession.startsWith(datItem.toString()));
        });
        setItems(filteredFilms);
        setActiveButtons(id);
        setActiveButtonsDates(datItem);
    }

    const filterItemAll = (id)=> {
        // const today = new Date();
        // today.setHours(0, 0, 1, 0);//устанавливаем часы
        const filteredFilms = Films.filter((film) => {
            return film.filmSessions.some((session) => Date.parse(session.dataSession)>today);

        });
        setItems(filteredFilms);
        setActiveButtons(id);
    }
  return (



      <div className="row justify-content-center ">
        {
               items.map((elem)=>{
                const {id,urlPicture,yearLimit,nameFilm,filmSessions} = elem;
          return(

             <div className="" key={id}>
            <div className="movie-item mb-15 tr-movie-active p-1">
              <div className={cn("",s.movieimg)}>
                {/*<a href="/movie-details"><img className={cn(s.imgposter)} src={urlPicture} alt="" /></a>*/}
                  <Link to={"/movie-details/"+id}><img className={cn(s.imgposter)} src={urlPicture} alt=""/></Link>
              </div>

              <div className={s.moviecontent}>

                <div className={s.top}>
                    <h5 className={s.title}><Link to={"/movie-details/"+id}>{nameFilm}</Link></h5>
                </div>

                <div className={s.bottom}>
                  <ul className={cn(activeButton.toString()==='all'&& s.none)}>
                       <MovieScenyNow filmSessions={elem.filmSessions} activeDate={activeButtonDate}/>
                  </ul>

                </div>
              </div>
            </div>
          </div>

          )
        })
      }
      </div>
      


  )
}

export default MovieNow