import React,{ useEffect } from 'react';
import $ from "jquery";
import 'magnific-popup';
import Counterup from '../NumberIncrementer';
import 'animate.css';
import NumberIncrementer from "../NumberIncrementer";
import NumberCounter from "../NumberIncrementer";
import s from "../homeone/Peredvijka.module.css";



const Peredvijka = () => {
  useEffect(()=>{
    
    $('.popup-video').magnificPopup({
			type: 'iframe'
		});
	

  },[])

  return (
    <section className="live-area live-bg fix" style={{backgroundImage:'url("../../img/bg/services_bg.jpg")'}}>
    <div className="container">
      <div className="row align-items-center">

        <div className="col-xl-7 col-lg-6">
          <div className="live-movie-img animate__animated animate__fadeInRight" data-wow-delay=".5s" data-wow-duration="5s">
            <img src="/img/images/mashinka_sleva.gif" alt="" />
          </div>
        </div>

        <div className="col-xl-5 col-lg-6">
          <div className="section-title title-style-two mb-25">
            {/*<span className="sub-title">Воспользуйся услугой личный кинозал</span>*/}
            {/*<h2 className="title">Приходи с друзьями и наслаждайтесь просмотром любимых фильмов</h2>*/}
          </div>



          <div className="live-movie-content">
            {/*<p>Каталог с фильмами постоянно обновляется</p>*/}
            <div className="live-fact-wrap">
              <div className="resolution">
                <h2 className="title" data-delay=".2s">показ фильмов</h2>
                <h3 className="title pt-10" data-delay=".2s">видеопередвижкой</h3>
              </div>
              <div className="active-customer">
              </div>
            </div>
          </div>

          <div className="mb-10 pt-40">

           <div className="mb-10 "> <a className={s.nameSpisok} href="https://drive.google.com/file/d/185C5iOjt2UnWMsuq4z6g4yGa9orY-J3G/view?usp=drive_link">&#x2014; График Обслуживания агрогородков</a></div>
            <div><a className={s.nameSpisok} href="https://drive.google.com/file/d/1CgJSCIYZXxllPlptrY4h81dFXnQzbpVI/view?usp=drive_link">&#x2014;  Список фильмов для показа</a></div>
          </div>
        </div>









      </div>
    </div>
  </section>
  )
}

export default Peredvijka