import React from "react";
import cn from "classnames";
import s from './login.module.css';

function FilmsAnonsAll(props) {

  const Sample=(item)=>{
      let check = props.Films.some(function(elem) {
          if (elem.nameFilm ===item) {
              return true;
          } else {
              return false;
          }
      });
return(check)
    }

    return (
        <>
            <section className="movie-area movie-bgMy"
                     style={{backgroundColor: "black", paddingTop: "50px"}}>
                <div className="container">
                    <div style={{
                        border: '4px solid yellow',
                        display: 'flex',
                        flexDirection: "column",
                        textAlign: 'left',
                        backgroundColor: "grey"
                    }}>

                        <h2 style={{margin: "20px 0 10px 0",textAlign:"center"}}>Анонс фильмов</h2>
                        <div style={{
                            minwidth: 200,
                            minHeight: 200,
                            backgroundColor: "yellow",
                            color: "black",
                            fontSize: 20,
                            padding: 20,
                            margin: "30px"
                        }}>



                            {

                                props.FilmsAnons.map((el, index) => (
                                        <div key={index}>
                                                <div>

                                                    <span  className={cn(Sample(el.name) && s.green)}> {el.dateRelies + ", []-->" + el.name + "<---[]; " + el.ageLimit + "; " + el.duration + "; " + el.country + "; " + el.genre}</span>
                                                </div>

                                        </div>
                                    )
                                )}

                            {/*{*/}
                            {/*    props.Films.map((el, index) => (*/}
                            {/*            <div key={index}>*/}
                            {/*                {el.nameFilm}*/}
                            {/*            </div>*/}
                            {/*        )*/}
                            {/*    )}*/}


                        </div>
            </div>
                </div>
                </section>















        </>
    )


}

export default FilmsAnonsAll