import React, { useState, useEffect } from 'react';

const NumberCounter = () => {
    const [number, setNumber] = useState(0);
    const targetValue = 200;
    const durationInSeconds = 4;

    useEffect(() => {
        const interval = setInterval(() => {
            if (number < targetValue) {
                setNumber((prevNumber) => prevNumber + 1);
            } else {
                clearInterval(interval);
            }
        }, (durationInSeconds * 500) / targetValue);

        return () => {
            clearInterval(interval);
        };
    }, [number]); // Добавили зависимость от number

    return (
        <div>
            {number}
        </div>
    );
};

export default NumberCounter;