import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import {Button, Form, Radio} from "antd";
import cn from "classnames";
import s from "./login.module.css";
import {FilmsRedactor} from "../../features/counter/filmsSlice";



export const SlidesM = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [ChangeSlide, setChangeSlide] = useState();

    const onChangeRadio = (e) => {
        setChangeSlide(props.Films[e.target.value].nameFilm);
    };

    const addSlide= () => {
        let newFilms = [...JSON.parse(JSON.stringify([...props.Films]))];
        newFilms.forEach((film) => {
            if (film.nameFilm === ChangeSlide) {
                film.slide=true
            }
        })
        dispatch(FilmsRedactor({FilmsF:JSON.stringify(newFilms)}))
    }
    const deleteSlide= () => {
        let newFilms = [...JSON.parse(JSON.stringify([...props.Films]))];
        newFilms.forEach((film) => {
            if (film.nameFilm === ChangeSlide) {
                film.slide=false
            }
        })
        dispatch(FilmsRedactor({FilmsF:JSON.stringify(newFilms)}))
    }
        return (
            <>

                <section className="movie-area movie-bgMy"
                         style={{backgroundColor: "black", paddingTop: "50px"}}>
                    <div className="container">
                        <div style={{
                            border: '4px solid yellow',
                            display: 'flex',
                            flexDirection: "column",
                            textAlign: 'left',
                            backgroundColor: "grey"
                        }}>

                            <h2 style={{margin: "20px 0 10px 0",textAlign:"center"}}>Фильмы для Слайда</h2>
                            <div style={{
                                minwidth: 200,
                                minHeight: 200,
                                backgroundColor: "yellow",
                                color: "black",
                                fontSize: 20,
                                padding: 20,
                                margin: "30px"
                            }}>

                                                <div>
                                                    <Form form={form}>
                                                        <Form.Item name="radio-group">
                                                            <Radio.Group onChange={onChangeRadio}>
                                                                {
                                                                    props.Films.map((el, index) => (
                                                                            <Radio className={cn (el.slide && s.now)} style={{marginRight: 35,marginTop:15, color: "black", width: 480, fontSize:18}} key={index}
                                                                                   value={index} name="radiogroup"> <span >{el.nameFilm} {el.id}</span>
                                                                            </Radio>
                                                                        )
                                                                    )}
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </Form>
                                                </div>
                                <div className='bg-white ' style={{textAlign:"center"}}>
                                    <Button onClick={addSlide} style={{width:200, height:50, fontSize:14, backgroundColor:"gray", margin:20}}   type="primary" htmlType="submit">добавить
                                    </Button>
                                    <Button onClick={deleteSlide} style={{width:200, height:50, fontSize:14, backgroundColor:"gray",margin:20}}   type="primary" htmlType="submit">удалить
                                    </Button>

                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )

}
