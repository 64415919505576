import React from 'react';
import Footer from '../components/Footer';
import Header from "../components/Header";
import AfishaMain from "../components/Afisha/AfishaMain";

const MoviesNow = (props) => {
    let Films=[...props.Films]




        return (
            <div>

                <Header/>
                <main>
                    <AfishaMain Films={Films}/>
                </main>
                <Footer/>
            </div>

        )





}

export default MoviesNow
