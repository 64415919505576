import React, {useEffect, useState} from "react";
import {Button} from "antd";
import {
    DeleteAllSeans,
    FilmsRedactor,
    GoogleSheetsPush,
} from "../../features/counter/filmsSlice";
import {useDispatch} from "react-redux";
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";


function FilmPanelEdit(props) {


let PushG=props.FilmsGooglePush;
let PushF=props.Films;
    const [textG, setTextG] = useState();
    const [textF, setTextF] = useState();

    function onChange(newValue) {
        setTextG(newValue)
    }
    function onChange2(newValue) {
        setTextF(newValue)
    }


    const dispatch = useDispatch()
    const Films=props.Films;

    const ButtonClickLoad= () => {
        setTextG(JSON.stringify(PushG,null,2))
    }
    const ButtonClickSave= () => {
        dispatch(GoogleSheetsPush({GoogleSheetsPush:textG,Films:props.Films}))
    }
    const ButtonClickDeleteSeans= () => {
        dispatch(DeleteAllSeans({GoogleSheetsPush:textG,Films:props.Films}))
    }

    const ButtonClickLoadFilms= () => {
        setTextF(JSON.stringify(PushF,null,2))

    }

    const ButtonClickSaveFilms= () => {
        dispatch(FilmsRedactor({FilmsF:textF}))
    }




        return (
            <>
                <section className="movie-area movie-bgMy"
                         style={{backgroundColor: "black", paddingTop:150, textAlign:"center"}}>
                    <div className="container" style={{ textAlign:'center'}}>
                        <div style={{border: '4px solid yellow',display:'flex', flexDirection:"column",backgroundColor: "grey"}} >
                            <h1 style={{marginTop:'10px'}}>Редактор JSON-файла</h1>
                                    <AceEditor
                                        mode="java"
                                        theme="github"
                                        onChange={onChange2}
                                        name="UNIQUE_ID_OF_DIV"
                                        editorProps={{ $blockScrolling: true }}
                                        value={textF}
                                         style={{  margin:30,minHeight:"80vh", width:'90%', marginLeft:'5%'}}
                                    />
                                <div className='bg-white ' style={{}}>
                                    <Button onClick={ButtonClickLoadFilms} style={{width:200, height:50, fontSize:14, backgroundColor:"gray", margin:20}}   type="primary" htmlType="submit">загрузда данных
                                    </Button>
                                    <Button onClick={ButtonClickSaveFilms} style={{width:200, height:50, fontSize:14, backgroundColor:"gray",margin:20}}   type="primary" htmlType="submit">сохранение и отправка
                                    </Button>
                                    <Button onClick={ButtonClickDeleteSeans} style={{width:200, height:50, fontSize:14, backgroundColor:"gray",margin:20}}   type="primary" htmlType="submit">удалить все сеансы
                                    </Button>
                                </div>
                        </div>
                    </div>
                </section>

                <section className="movie-area movie-bgMy"
                         style={{backgroundColor: "black", paddingTop:150, textAlign:"center"}}>
                    <div className="container" style={{ textAlign:'center'}}>
                        <div style={{border: '4px solid yellow',display:'flex', flexDirection:"column",backgroundColor: "grey"}} >
                            <h1 style={{marginTop:'10px'}}>Редактор данных с Google-страницы</h1>
                            <AceEditor
                                mode="java"
                                theme="github"
                                onChange={onChange}
                                name="UNIQUE_ID_OF_DIV2"
                                editorProps={{ $blockScrolling: true }}
                                value={textG}
                                style={{  margin:30,minHeight:"80vh", width:'90%', marginLeft:'5%'}}
                            />
                            <div className='bg-white ' style={{}}>
                                <Button onClick={ButtonClickLoad} style={{width:200, height:50, fontSize:14, backgroundColor:"gray", margin:20}}   type="primary" htmlType="submit">загрузда данных
                                </Button>
                                <Button onClick={ButtonClickSave} style={{width:200, height:50, fontSize:14, backgroundColor:"gray",margin:20}}   type="primary" htmlType="submit">сохранение и отправка
                                </Button>

                            </div>
                        </div>
                    </div>
                </section>







            </>
        )
    }

export default FilmPanelEdit