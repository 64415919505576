import React, {useContext, useEffect, useState} from 'react';
import s from './Afisha.module.css';
import cn from 'classnames';
import {Link} from "react-router-dom";
import {FilmsContext} from "../FilmsContext";


const AfishaSoon = (props) => {
  // const Films  = useContext(FilmsContext);
  let newFilms=[...props.Films]
  let filmsWithoutSessions = [];

  newFilms.forEach(film => {
    if (film.filmSessions.length === 0) {
      filmsWithoutSessions.push(film);
    }
  });

  return (

  <section className="top-rated-movie tr-movie-bg" style={{backgroundImage:'url("../../img/bg/tr_movies_bg.jpg")'}}>
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="section-title text-center mb-50">
            <h2 className="title">Скоро в кино</h2>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="tr-movie-menu-active text-center">



          </div>
        </div>
      </div>
      <div className="row tr-movie-active">
        {
            filmsWithoutSessions.map((elem)=>{
                const {id,urlPicture,yearLimit,nameFilm,filmSessions} = elem;
          return(

             <div className="col-xl-3 col-lg-4 col-sm-6 grid-item grid-sizer cat-two" key={id}>
            <div className="movie-item mb-60">
              <div className="movie-poster">
                <div className={s.topleft}>+{yearLimit}</div>
                  <Link to={"/movie-details/"+id}><img src={urlPicture} alt="" /></Link>
              </div>
              <div className={s.moviecontent}>
                <div className={s.top}>
                  <h5 className={s.title}><Link to={"/movie-details/"+id}>{nameFilm}</Link></h5>
                </div>
                <div className={s.bottom}>
                  <ul className={cn( s.none)}>

                       {/*<AfishaSceny filmSessions={elem.filmSessions} activeDate={activeButtonDate}/>*/}




                  </ul>
                </div>
              </div>
            </div>
          </div>

          )
        })



      }
      </div>
      
    </div>
  </section>
  )
}

export default AfishaSoon